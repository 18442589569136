<template>
  <div>
    <van-tabs @change="change" v-model:active="active">
      <van-tab title="钻石商品" name="0">
        <div class="list-wrap">
          <ul class="list">
            <li @click="select(item)" :key="index" v-for="(item,index) in list" class="item" :class="vipItem.id==item.id?'active':''">
              <h5>{{item.tag}}</h5>
              <h3><font class="small-font"></font>{{item.coins}}钻石</h3>
              <p class="original-price"><s>{{item.price}}</s></p>
            </li>
          </ul>
          <ul class="channel-list">
            <li @click="selectChannel(item)" :class="channelItem.payType==item.payType?'active':''" v-for="(item,index) in channelList" class="item">
              {{item.title}}
              <img v-if="item.payType=='1'" src="../assets/icon_pay_ali.png">
              <img v-if="item.payType=='2'" src="../assets/icon_wechat.png">
            </li>
          </ul>
          <div class="button-box">
            <van-button @click="submit" type="info" block>提交</van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="VIP商品" name="1">
          <div class="list-wrap">
             <ul class="list">
               <li @click="select(item)" :key="index" v-for="(item,index) in list" class="item" :class="vipItem.id==item.id?'active':''">
                      <h5>{{item.title}}</h5>
                 <h3><font class="small-font">￥</font>{{item.discount}}</h3>
                 <p class="original-price"><s>￥{{item.money}}</s></p>
               </li>
             </ul>
             <ul class="channel-list">
               <li @click="selectChannel(item)" :class="channelItem.payType==item.payType?'active':''" v-for="(item,index) in channelList" class="item">
                 {{item.title}}
                  <img v-if="item.payType=='1'" src="../assets/icon_pay_ali.png">
                  <img v-if="item.payType=='2'" src="../assets/icon_wechat.png">
               </li>
             </ul>
             <div class="button-box">
               <van-button @click="submit" type="info" block>提交</van-button>
             </div>
          </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ArticleList from "@/components/ArticleList";
import {getVipList,getGoldList,orderSubmit,getChannelList} from "@/api/user";
import {Toast} from "vant";

export default {
  name: "recharge",
  components: {ArticleList},
  data() {
    return {
      active:0,
      list:[],
      vipItem:{},
      channelList:[],
      channelItem:{}
    }
  },
  created() {
    this.getList()
    getChannelList({}).then((res) => {
      if (res.code == 0) {
        this.channelList =res.result||[]
        this.channelItem=this.channelList[0]
      }
    })
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    getList(){
      this.list=[]
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (this.active==0)
      {
        getGoldList({}).then((res) => {
          if (res.code == 0) {
            this.list =res.result||[]
            this.vipItem=this.list[0]
          }
        })
      }
      else {
        getVipList({}).then((res) => {
          if (res.code == 0) {
            this.list =res.result||[]
            this.vipItem=this.list[0]
          }
        })
      }
    },
    change(e){
      this.getList()
    },
    select(item){
      this.vipItem=item
    },
    submit(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      orderSubmit({itemId:this.vipItem.id,channelId:this.channelItem.payType,type:this.active}).then((res) => {
        if (res.code == 0) {

        }
      })
    },
    selectChannel(item){
      this.channelItem=item
    }
  }
}
</script>

<style scoped>

.poster-btn {
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  width: 100px;
  height: 50px;
  position: absolute;
  z-index: 99;
  bottom: 15px;
  color: #0066ff;
  right: 3vw;
  line-height: 27px;
}

.poster-btn-img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.in-title {
  color: rgba(0, 0, 0, .7);
  letter-spacing: 1px;
  width: 97%;
  margin: auto;
  height: 50px;
  border: none;
  font-size: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.in-content {
  width: 97%;
  margin-top: 20px;
  font-size: 15px;
  color: rgba(0, 0, 0, .5);
  letter-spacing: 1px;
  border: none;
  line-height: 25px;
}

.footer {
  position: absolute;
  border-bottom: 0;
  font-weight: bold;
  font-size: 14px;
  color: #0066ff;
  padding-top: 4px;
}
.list-wrap{
  padding: 15px;
}
.list{
  display: flex;
  flex-wrap: wrap;
  gap:10px
}
.list .item{
  width: calc(33.33333% - 36px);
  padding: 10px;
  background:#EEE6CD;
  border-radius: 15px;
  border: 3px solid #EBEBEB;
}
.list .item.active{
  border-color: #F1BA6B;
}
.small-font{
  font-size: 12px;
}
.list .item h3{
  color:#4D4D4D;
}
.original-price{
 color:#CE994F;
}
.button-box{
  padding: 20px;
}
.channel-list{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.channel-list .item{
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  padding: 10px;
  width: calc(50% - 30px);
}
.channel-list .item img{
  width: 40px;
  height: 40px;
}
.channel-list .item.active{
  border-color: #F1BA6B;
}
</style>